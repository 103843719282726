@use "sass:map";
@import "../../../styles/constants/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/sizes";
@import "~@oup/shared-front-end/src/styles/themes/base/colors";
@import "~@oup/shared-front-end/src/styles/themes/base/typography";

.icon {
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  background-color: map-get($primary-colors, primary);
  cursor: pointer;

  &:focus {
    border-color: $color-borders-v2;
    box-shadow: inset 0 1px 1px $contrast-light-shadow, 0 0 10px 1px $contrast-light-blue;
    outline: none;
  }

  &:hover {
    background-color: $color-astronaut-blue;
  }

  svg {
    height: 1.5rem;
    fill: $color-white;
  }
}


.button {
  width: 15px;
  margin-right: 33px;
}

.list {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid map-get($primary, primary20);
}

.info {
  display: flex;
  align-items: center;
}

.broken {
  line-height: 0;

  img {
    width: 2.75rem;
    border: 1px solid map-get($primary, primary20);
    border-radius: $border-radius-small ;
    background-color: map-get($primary, primary100);
  }
}

.label {
  min-width: calc(100% - 3ch - 5.25rem);
  margin: $gap-4 0;
}

.title {
  color: map-get($primary, primary100);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
}

.iconMenu>button {
  max-height: 2.5rem !important;
  max-width: 2.5rem !important;
}

.line {
  margin-top: 0.94rem;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  margin-right: $gap-2;
  label {
    margin-top: $gap-2;
    background-color: $color-white !important;
  }

  div {
    margin-top: $gap-2;
    padding-right: $gap-1;
  }
}

hr:last-child {
  display: none;
}

.menu {
  position: relative;
}

.pack {
  padding-right: 15px;
  font-family: 'open-sans';
  color: map-get($validation, validation-green100);
}

.toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.toggleTitle {
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
  color: map-get($primary, primary100);
}

.toggleInfo {
  p {
    display: flex;
    flex-wrap: wrap;
    font-family: $font-family-text-font;
    font-size: $font-size-base;
    font-weight: $font-weight-400;
    line-height: $line-height-default-150;
    color: map-get($primary, primary100);
    margin-top: $gap-2;
    margin-bottom: $gap-2;

    span {
      font-family: $font-family-text-font;
      font-size: $font-size-medium;
      font-weight: $font-weight-700;
      line-height: $line-height-120;
      color: map-get($primary, primary100);
      text-align: $text-align-center;
      padding: 0 20px 0 12px;
    }
  }
}

.gradesToggleInfo {
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  line-height: $line-height-default-150;
  color: map-get($primary, primary100);
  margin-top: $gap-2;
  margin-bottom: $gap-2;
  margin-left: 46px;
}

.container {
  max-width: calc(100% - 3.25rem);
  display: flex;
  align-items: flex-start;
}

.oxfordHubItem {
  margin: 0;
  display: flex;
  align-items: center;
  align-self: center;

  >p {
    margin: 0;
  }
}

.pageSelectorContainer {
  display: flex;
  flex-basis: 100%;
  column-gap: $gap-3;
  margin-top: calc(-1 * $gap-1);

  input {
    width: 6.25rem !important;
    min-height: 2.5rem !important;
    max-height: 2.5rem !important;
    margin-top: -0.5rem;
  }

  svg {
    margin-top: -0.25rem;
  }

  label p {
    font-weight: $font-weight-600;
  }

  p {
    width: 6.25rem;
    font-weight: $font-weight-700;
  }
}

.pageValueDescriptionContainer {
  color: $color-black;
}

.pageValue {
  font-weight: bold;
  display: inline-block;
}

.validationIcon {
  width: $icon-size-regular;
  height: $icon-size-regular;
  margin-left: -1px;
}

.pages {
  text-wrap: nowrap;
  display: flex;
  height: 1.875rem;
  column-gap: $gap-2;
  padding-bottom: $gap-2;


  span {
    width: 3ch;
    max-width: 3ch;
    min-width: 3ch;
    font-family: $font-family-text-font;
    font-size: $font-size-medium;
    font-weight: $font-weight-700;
    line-height: $line-height-120;
    color: map-get($primary, primary100);
    text-align: $text-align-center;
  }
}

.errorMessage {
  margin-top: 10px;
  background-color: map-get($primary, primary10);
  padding: 0.25rem 0.5rem 0.25rem 0.75rem;
  border-radius: $border-radius-default;

  path {
    color: map-get($primary, primary100);
  }

  div:first-child {
    font-size: 1rem !important;
    background-color: map-get($primary, primary10);
  }
}

.paddingLeftObject {
  width: calc(3ch + $gap-1 - 1px);
  min-width: calc(3ch + $gap-1 - 1px);
  max-width: calc(3ch + $gap-1 - 1px);
}

.confirmSingleLabel {
  margin-top: 0;
}

.checkboxImageContainer {
  display: flex;
  align-items: center;
  flex-flow: row;
  margin: $gap-4 $gap-3 $gap-4 $gap-4;
  label {
    margin: 0;
  }
}

.dropDownButton {
  margin: $gap-4 0 0 0;
}

.HubDropdownMenu {
  fill: map.get($primary, primary100) !important;
}
